import React from 'react';
import { Link as GatsbyLink } from "gatsby";
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import sectionStyles from './index.module.css';
import { withStyles, MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';

const orangeTheme = createMuiTheme({ palette: { primary:  { main : '#000000'}}})

class MainSection extends React.Component {
  render() {
    return (
      <section className={`${sectionStyles.pageSection} section`}>
        <Grid container spacing={0} alignItems="center" className={`${sectionStyles.contentContainer} contentContainer`}>
          <Grid item xs={12}>
            <div className={`${sectionStyles.articleContainer} m-auto`}>
              <div className={`${sectionStyles.sectionTitle} sectionTitle`}>M3 Solutions Services Price</div>
              <div className={`${sectionStyles.sectionTitleUnderline} sectionTitleUnderline ul-center`} />
              <div className={`${sectionStyles.articleBigTitle} articleBigTitle`}>
                Interested in collection services? <br/> Ask us about M3 Plus with <br/> full service collection option.
              </div>
            </div>
          </Grid>
          <Grid item xs={12}>
            <MuiThemeProvider theme={orangeTheme}>
              <Button variant="contained" className="m-bottom-20" color="primary" size="large" 
              style={{marginTop: 55, paddingLeft: 50, paddingRight: 50, paddingTop:10, paddingBottom:10, fontFamily: 'Oswald', fontSize: '30px',
            fontWeight: '100'}} 
              component={GatsbyLink} to="/contact/">
                Inquire About M3 Plus
              </Button>
            </MuiThemeProvider>
          </Grid>
        </Grid>
      </section>
    );
  }
}

export default MainSection;