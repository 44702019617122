import React from 'react';
import Layout from '../components/Layout';
import {
  MainSection,
  PricingDetailsSection
} from '../components/Pricing';
import ContactMainSection from '../components/Contact/Main';
import { graphql } from 'gatsby';
import {
  TransitionGroup,
  Transition as ReactTransition,
} from "react-transition-group"

const pluginWrapper = () => {
  require('fullpage.js/vendors/scrolloverflow');
};
const timeout = 1000;

class Pricing extends React.Component {

  render() {
    const { data } = this.props;
    return (
      <Layout location={this.props.location} title="Pricing">
        <head>
          <title>{`Pricing | ${data.site.siteMetadata.title}`}</title>
          <link rel="canonical" href={`${data.site.siteMetadata.url}/pricing/`} />
        </head>
        <TransitionGroup>
          <ReactTransition
            key={'/pricing'}
            timeout={{
              enter: timeout,
              exit: timeout,
            }}
          >
            <div>
              <MainSection />
              <PricingDetailsSection />
              <ContactMainSection/>  
            </div>
          </ReactTransition>
        </TransitionGroup>
      </Layout>
    );
  }
}

export default Pricing;

export const query = graphql`
  query PricingPageQuery {
    site {
      siteMetadata {
        title
        url
      }
    }
  }
`;
