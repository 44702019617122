import React from 'react';
import ItemsCarousel from 'react-items-carousel';
import { Link as GatsbyLink } from "gatsby";
import { withStyles, MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Hidden from '@material-ui/core/Hidden';
import Fab from '@material-ui/core/Fab';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Slider from '@material-ui/lab/Slider';
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import CheckIcon from '@material-ui/icons/Check';
import sectionStyles from './index.module.css';

const StyledTabs = withStyles({
  indicator: {
    display: 'flex',
    height: '5px',
    justifyContent: 'center',
    backgroundColor: 'transparent',
    '& > div': {
      maxWidth: 100,
      width: '100%',
      backgroundColor: '#F15A22',
    },
  },
})(props => <Tabs {...props} TabIndicatorProps={{ children: <div /> }} />);

const StyledTab = withStyles(theme => ({
  root: {
    fontSize: '30px',
    minWidth: '300px',
    borderTop: '1px solid #707070',
    borderBottom: '1px solid #707070',
    ['@media (max-width:599px)']: {
      minWidth: '50%'
    }
  },
}))(props => <Tab disableRipple {...props} />);

const StyledSlider = withStyles({
  root: {
    color: '#F15A22',
    height: 8,
    textAlign: 'initial'
  },
  thumb: {
    height: 42,
    width: 42,
    backgroundColor: '#fff',
    border: '2px solid currentColor',
    marginTop: -11,
    marginLeft: -21,
    '&:focus,&:hover,&$active': {
      boxShadow: 'inherit',
    },
  },
  active: {},
  valueLabel: {
    left: 'calc(-50% + 4px)',
  },
  track: {
    height: 20,
    width: 20,
    borderRadius: 10,
  },
  rail: {
    height: 20,
    borderRadius: 10,
    color: '#ffffff',
    opacity: 1,
    border: '2px solid #C8C8C8'
  },
})(Slider);

const StyledFab = withStyles({
  root: {
    backgroundColor: '#F15A22',
    '&:hover': {
      backgroundColor: '#d3430d'
    }
  },
})(Fab);

const StaticFab = withStyles({
  root: {
    backgroundColor: '#F15A22',
    '&.MuiFab-sizeSmall': {
      width: '35px',
      height: '35px'
    },
    '&:hover': {
      cursor: 'initial',
      backgroundColor: '#F15A22'
    }
  },
})(Fab);

const orangeTheme = createMuiTheme({ palette: { primary: { main: '#000000' } } })

const M3_BASIC_PLAN_PRICE = 50;
const M3_PLAN_PRICE_INCREMENT = 20;
const TRANSACTION_VOLUME_LARGE_PRICE_INCREMENT = 50;
const CREDITCARD_FEE_PERCENT = 2.9;

const formatter = new Intl.NumberFormat('en-CA', {
  style: 'currency',
  currency: 'CAD',
  minimumFractionDigits: 2
});

class PricingDetailsSection extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeCarouselItemIndex: 0,
      transactionVolumeTabIndex: 0,
      transactionVolumeSliderIndex: 10,
      averageTransactionAmountSliderIndex: 100,
    };
  }

  changeActiveCarouselItem = (activeCarouselItemIndex) => this.setState({ activeCarouselItemIndex });

  handleTransactionVolumeChange = (event, newValue) => {
    this.setState({
      transactionVolumeTabIndex: newValue
    });
  }

  handleTransactionVolumeSliderChange = (event, newValue) => {
    this.setState({
      transactionVolumeSliderIndex: newValue
    });;
  };

  handleAverageTransactionAmountSliderChange = (event, newValue) => {
    this.setState({
      averageTransactionAmountSliderIndex: newValue
    });;
  };

  render() {
    return (
      <section className={`${sectionStyles.pageSection} section`}>
        <Grid container spacing={0} alignItems="center" className={`${sectionStyles.contentContainer} contentContainer`}>
          <Grid item xs={12}>
            <div className={`${sectionStyles.articleContainer} m-auto`}>
              <div className={`${sectionStyles.sectionTitleUnderline} sectionTitleUnderline ul-center`} />
              <div className={`${sectionStyles.articleBigTitle} articleBigTitle`}>
                Simple Pricing
              </div>
              <div className={`${sectionStyles.pricingDetailsText}`}>
                Pricing is based on the number of members.
              </div>
              <ItemsCarousel
                className={`${sectionStyles.pricingModelCarousel}`}
                activePosition={'center'}
                numberOfCards={1}
                slidesToScroll={1}
                showSlither={false}
                firstAndLastGutter={false}
                requestToChangeActive={this.changeActiveCarouselItem}
                activeItemIndex={this.state.activeCarouselItemIndex}
                rightChevron={this.renderRightArrowIconFab()}
                leftChevron={this.renderLeftArrowIconFab()}
                chevronWidth={60}
              >
                {Array.from(['M3 Basic', 'M3']).map((value, i) =>
                  <div
                    key={i}
                    className={`${sectionStyles.pricingModelNameContainer}`}
                  >
                    <div className={`${sectionStyles.pricingModelName}`}>
                      {value}
                    </div>
                  </div>
                )}
              </ItemsCarousel>
              <div className={`${sectionStyles.transactionVolumeText}`}>
                Members
              </div>
              <StyledTabs
                value={this.state.transactionVolumeTabIndex}
                onChange={this.handleTransactionVolumeChange}
                centered
              >
                <StyledTab label="1 - 100" />
                <StyledTab label="100+" />
              </StyledTabs>
              <div className={`${sectionStyles.dollarPrice}`}>
                ${this.calculatePlanPrice()}
              </div>
              <div className={`${sectionStyles.perMonth}`}>
                / month
              </div>
            </div>
          </Grid>
          <Grid item xs={12}>
            <MuiThemeProvider theme={orangeTheme}>
              <Button variant="contained" className="m-top-20 m-bottom-20" color="primary" size="large"
                style={{
                  marginTop: 55, paddingLeft: 50, paddingRight: 50, paddingTop: 10, paddingBottom: 10, fontFamily: 'Oswald', fontSize: '30px',
                  fontWeight: '100'
                }}
                component={GatsbyLink} to="/contact/">
                Get Started
              </Button>
            </MuiThemeProvider>
          </Grid>
        </Grid>
        <Grid container spacing={0} alignItems="center" className={`${sectionStyles.greyBackground}`}>
          <Grid item xs={12}>
            <Grid container spacing={0} alignItems="center" className={`${sectionStyles.contentContainer} contentContainer`}>
              <Grid item xs={12} className="p-top-20 p-bottom-50">
                <div className={`${sectionStyles.includedFeaturesTitle} m-bottom-50`}>
                  Included with every <span>M3{this.state.activeCarouselItemIndex === 0 ? ' Basic' : ''}</span> account
                </div>
                <Grid container spacing={2} justify="space-between" alignItems="center">
                  <Grid item xs={12} md={6} lg={4}>
                    <div className={`${sectionStyles.includedFeatures}`}>
                      <div className={`${sectionStyles.featureName}`}>
                        Membership Management
                      </div>
                      <StaticFab color="primary" size="small" className="float-right">
                        <CheckIcon />
                      </StaticFab>
                    </div>
                  </Grid>
                  <Grid item xs={12} md={6} lg={4}>
                    <div className={`${sectionStyles.includedFeatures}`}>
                      <div className={`${sectionStyles.featureName}`}>
                        Attendance Tracking
                      </div>
                      <StaticFab color="primary" size="small" className="float-right">
                        <CheckIcon />
                      </StaticFab>
                    </div>
                  </Grid>
                  <Grid item xs={12} md={6} lg={4}>
                    <div className={`${sectionStyles.includedFeatures}`}>
                      <div className={`${sectionStyles.featureName}`}>
                        Face ID Check-In
                      </div>
                      <StaticFab color="primary" size="small" className="float-right">
                        <CheckIcon />
                      </StaticFab>
                    </div>
                  </Grid>
                  <Grid item xs={12} md={6} lg={4}>
                    <div className={`${sectionStyles.includedFeatures}`}>
                      <div className={`${sectionStyles.featureName}`}>
                        SMS / Email Messaging
                      </div>
                      <StaticFab color="primary" size="small" className="float-right">
                        <CheckIcon />
                      </StaticFab>
                    </div>
                  </Grid>
                  <Grid item xs={12} md={6} lg={4}>
                    <div className={`${sectionStyles.includedFeatures}`}>
                      <div className={`${sectionStyles.featureName}`}>
                        Reports
                      </div>
                      <StaticFab color="primary" size="small" className="float-right">
                        <CheckIcon />
                      </StaticFab>
                    </div>
                  </Grid>
                  <Grid item xs={12} md={6} lg={4}>
                    <div className={`${sectionStyles.includedFeatures}`}>
                      <div className={`${sectionStyles.featureName}`}>
                        Staff Accounts
                      </div>
                      <StaticFab color="primary" size="small" className="float-right">
                        <CheckIcon />
                      </StaticFab>
                    </div>
                  </Grid>
                  <Hidden mdDown>
                    <Grid item lg={4} />
                  </Hidden>
                  <Grid item xs={12} md={6} lg={4}>
                    <div className={`${sectionStyles.includedFeatures}`}>
                      <div className={`${sectionStyles.featureName}`}>
                        Automated Payments
                      </div>
                      <StaticFab color="primary" size="small" className="float-right" disabled={this.state.activeCarouselItemIndex === 0}>
                        <CheckIcon />
                      </StaticFab>
                    </div>
                  </Grid>
                  <Hidden mdDown>
                    <Grid item lg={4} />
                  </Hidden>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid container spacing={0} alignItems="center" className={`${sectionStyles.contentContainer} contentContainer`}>
          <Grid item xs={12}>
            <div className={`${sectionStyles.articleContainer} m-auto`}>
              <div className={`${sectionStyles.sectionTitleUnderline} sectionTitleUnderline ul-center`} />
              <div className={`${sectionStyles.articleBigTitle} articleBigTitle`}>
                Measurable Savings
              </div>
              <div className={`${sectionStyles.pricingDetailsText} m-bottom-50`}>
                See how much you can save in transaction processing fees with Mimosa Solutions 
              </div>
            </div>
          </Grid>
        </Grid>
        <Grid container spacing={0} alignItems="center" className={`${sectionStyles.greyBackground}`}>
          <Grid item xs={12}>
            <Grid container spacing={0} alignItems="center" className={`${sectionStyles.contentContainer} contentContainer p-top-50 p-bottom-50`}>
              <Grid item xs={12} lg={6} xl={5} className="p-tom-20 p-bottom-20">
                <Grid container spacing={0} alignItems="center">
                  <Grid item xs={12}>
                    <div className={`${sectionStyles.sliderLabel}`}>
                      <span className={`${sectionStyles.sliderLabelText}`}>Number of Monthly Transactions</span>
                      <span className={`${sectionStyles.sliderValue} float-right p-bottom-20`}>
                        {this.state.transactionVolumeSliderIndex}
                      </span>
                    </div>
                    <StyledSlider
                      className="p-bottom-50"
                      valueLabelDisplay="auto"
                      aria-label="slider"
                      max={200}
                      onChange={this.handleTransactionVolumeSliderChange}
                      value={this.state.transactionVolumeSliderIndex}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <div className={`${sectionStyles.sliderLabel}`}>
                      <span className={`${sectionStyles.sliderLabelText}`}>Average Transaction Amount</span>
                      <span className={`${sectionStyles.sliderValue}`}>
                        ${this.state.averageTransactionAmountSliderIndex}
                      </span>
                    </div>
                    <StyledSlider
                      className="p-bottom-50"
                      valueLabelDisplay="auto"
                      aria-label="slider"
                      max={1000}
                      onChange={this.handleAverageTransactionAmountSliderChange}
                      value={this.state.averageTransactionAmountSliderIndex}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} lg="auto" xl={2} />
              <Grid item xs={12} lg={6} xl={5} className="p-20">
                <Grid container spacing={0} alignItems="center">
                  <Grid item xs={12}>
                    <div className={`${sectionStyles.priceCalculationInformationContainer}`}>
                      <Hidden smDown>
                        <div>
                          <div className={`${sectionStyles.priceDetailBubble} m-top-20 m-bottom-20`}>
                            <div className={`${sectionStyles.priceDetailBubbleContent}`}>
                              <div className={`${sectionStyles.priceDetailBubblePrice}`}>
                                ${this.calculateM3Fee()}
                              </div>
                              <div className={`${sectionStyles.priceDetailBubbleText}`}>
                                M3 Fees
                              </div>
                            </div>
                          </div>
                          <div className={`${sectionStyles.priceDetailBubble} m-top-20 m-bottom-20`}>
                            <div className={`${sectionStyles.priceDetailBubbleContent}`}>
                              <div className={`${sectionStyles.priceDetailBubblePrice}`}>
                                {formatter.format(this.calculateCreditCardFee())}
                              </div>
                              <div className={`${sectionStyles.priceDetailBubbleText}`}>
                                Others' Fees
                              </div>
                            </div>
                          </div>
                          <div className={`${sectionStyles.creditCardFeeCalculationInformation}`}>
                            * Calculated on a 2.9%  <br />Credit Card Fee for Others
                          </div>
                        </div>
                      </Hidden>
                      <div className={`${sectionStyles.savingPriceCalculationCircle}`} style={{ marginLeft: '30px', marginBottom: '50px' }}>
                        <div className={`${sectionStyles.savingPriceCalculationCircleContent}`}>
                          <div className={`${sectionStyles.informationText}`}>Start Saving Up To</div>
                          <div className={`${sectionStyles.savingPrice}`}>{formatter.format(this.calculateMonthlySaving())}</div>
                          <div className={`${sectionStyles.informationText}`}>Monthly with M3</div>
                        </div>
                      </div>
                    </div>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </section>
    );
  }

  renderLeftArrowIconFab = () => {
    return (
      <StyledFab color="primary">
        <KeyboardArrowLeftIcon fontSize="large" />
      </StyledFab>
    );
  }

  renderRightArrowIconFab = () => {
    return (
      <StyledFab color="primary">
        <KeyboardArrowRightIcon fontSize="large" />
      </StyledFab>
    );
  }

  calculatePlanPrice = () => {

    const { activeCarouselItemIndex, transactionVolumeTabIndex } = this.state;
    let calculatedPrice = M3_BASIC_PLAN_PRICE;

    calculatedPrice += (activeCarouselItemIndex === 1) ? M3_PLAN_PRICE_INCREMENT : 0;
    calculatedPrice += (transactionVolumeTabIndex === 1) ? TRANSACTION_VOLUME_LARGE_PRICE_INCREMENT : 0;

    return calculatedPrice;
  }

  calculateM3Fee = () => {
    return (this.state.transactionVolumeSliderIndex * 0.25).toFixed(2);
  }

  calculateCreditCardFee = () => {
    const { transactionVolumeSliderIndex, averageTransactionAmountSliderIndex } = this.state;

    return (transactionVolumeSliderIndex * averageTransactionAmountSliderIndex) * (CREDITCARD_FEE_PERCENT / 100);
  }

  calculateMonthlySaving = () => {
    const mimosaFee = this.calculateM3Fee();
    const creditCardFee = this.calculateCreditCardFee();

    return creditCardFee - mimosaFee;
  }
}

export default PricingDetailsSection;